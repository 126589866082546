import { render, staticRenderFns } from "./attivazioneUtenteLink.vue?vue&type=template&id=c2186b16&"
import script from "./attivazioneUtenteLink.vue?vue&type=script&lang=js&"
export * from "./attivazioneUtenteLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports