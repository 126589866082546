<template>
  <div class="emailRegistrazione ">
    <div class="row no-guters">
      <div class="col-10 offset-1">
        <div class="row no-gutters align-items-center o-main">
          <div class="col-lg-6 ">
            <div class="row no-gutters mt-2 ">
              <div v-if="error==null" class="m-card m-card--success ">
                <div class="row no-gutters p-3">
                  <div class="col-xl-12">
                    <h1>
                      <strong>{{ $t(labelRadix+".title") }}</strong>
                    </h1>
                    <div class="m-card__text">
                      <p v-if="false" class=" m-card__text">
                        {{ $t("attivazioneUtenteLink.subtitle_0") }}
                      </p>
                      <p>
                        <strong>{{
                          $t(labelRadix+".message")
                        }}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="error!=null" class="m-card m-card--dark ">
                <div class="row no-gutters p-3">
                  <div class="col-xl-12">
                    <h1>
                      <strong>{{ $t(labelRadix+".title_error") }}</strong>
                    </h1>
                    <div class="m-card__text">
                      <p class=" m-card__text">
                        {{ $t(labelRadix+".subtitle_0_error") }}
                      </p>
                      <p>
                        <strong>{{
                            $t(labelRadix+".message_error")
                          }}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <b-button
                variant="primary"
                size=""
                class="text-light text-uppercase mt-4 ml-4"
                :to="{ path: '/' }"
                >{{ $t("auth.go_home") }}</b-button
              >
            </div>
          </div>
          <div v-if="error==null" class="col-lg-5 offset-lg-1 d-none d-lg-block">
            <div class="o-text-image">
              <b-img-lazy
                src="../assets/success-flag.svg"
                alt="email_send"
              ></b-img-lazy>
              <p class="text-success">
                <strong>{{ $t(labelRadix+".title_0") }}</strong>
              </p>
            </div>
          </div>
          <div v-if="error!=null" class="col-lg-5 offset-lg-1 d-none d-lg-block">
            <div class="o-text-image">
              <b-img-lazy
                  src="../assets/fail-flag.svg"
                  alt="email_send"
              ></b-img-lazy>
              <p class="text-danger">
                <strong>{{ this.error }}</strong>
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "attivazioneUtenteLink",

  data() {
    return {
      service:"activate",
      error:null
    };
  },
  created() {
    this.getData();
  },
  computed: {
    labelRadix:function ()
    {
      switch(this.service)
      {
        case "reactivate":
          return "riattivaUtente.callback";
        case "forgot":
          return "forgot.callback";
        default:
          return "attivazioneUtenteLink";
      }
    }
  },

  methods: {
    getData() {
      let uri = window.location.href.split('?');
      if (uri.length == 2) {
        let vars = uri[1].split('&');
        let getVars = {};
        let tmp = '';
        vars.forEach(function (v) {
          tmp = v.split('=');
          if (tmp.length == 2)
            getVars[tmp[0]] = tmp[1];
        });

        if ('service' in getVars)
        {
          this.service=getVars.service;
        }
        if ('error' in getVars)
        {
          this.error = decodeURI(getVars.error);

        }
      }
    }
  },
};
</script>
